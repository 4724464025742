import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import { Heading, Link } from '../../common';
import ArticleCard from '../Card/ArticleCard';
import classnames from 'classnames';

const Posts = ({ pinnedPost, newsLink, files, ...props }) => {
  const {
    drupal: {
      nodeQuery: { entities },
    },
  } = useStaticQuery(graphql`
    query postsQuery {
      drupal {
        nodeQuery(
          limit: 3000
          filter: {
            conditions: [
              { field: "type", value: "post", operator: EQUAL }
              { field: "status", value: "1", operator: EQUAL }
            ]
          }
          sort: { field: "created" }
        ) {
          entities {
            ...article
            ... on Drupal_NodePost {
              entityId
              fieldFeaturedMedia {
                entity {
                  entityId
                }
              }
            }
          }
        }
      }
    }
  `);

  if (entities.length < 1) return null;

  let articles = pinnedPost
    ? entities.reduce((acc, e) => (pinnedPost === e.entityId ? [{ ...e, pinned: true }, ...acc] : [...acc, e]), [])
    : entities;

  articles = articles[0].fieldFeaturedMedia ? articles.slice(0, 4) : articles.slice(0, 5);

  return (
    <Container {...props}>
      <Row>
        <Col>
          <Heading as="h2" styledAs="h5" color="gray" className="mb-4">
            News
          </Heading>
        </Col>
        <Col className="text-right">
          <Link href={newsLink} className="lead-2">
            View all
          </Link>
        </Col>
      </Row>

      <Row>
        {articles.map(({ entityId, ...article }, i) => (
          <Col
            lg={i === 0 ? 12 : 6}
            xl={i === 0 ? (article.fieldFeaturedMedia ? 12 : 8) : 4}
            key={entityId}
            className={classnames(i > 2 ? 'd-none d-lg-flex' : 'd-flex', 'article-card-col')}
          >
            <ArticleCard
              article={article}
              className="flex-fill"
              headerAs="h3"
              horizontal
              pinned={i === 0}
              truncateLines={i === 0 ? 3 : 4}
              files={files}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Posts;
