import React from 'react';
import { graphql } from 'gatsby';
import { Col, Row } from 'react-bootstrap';
import LinkCard from '../Card/LinkCard';

export const query = graphql`
  fragment popularTopics on Drupal_ParagraphPopularTopics {
    entityId
    fieldTopicsIcon {
      entity {
        ...childImageField
      }
    }
    fieldTopicsLink {
      entity {
        entityLabel
        entityUrl {
          path
          ... on Drupal_EntityCanonicalUrl {
            breadcrumb {
              text
            }
          }
        }
      }
    }
  }
`;

const PopularTopics = ({ popularTopics, files }) => (
  <Row>
    {popularTopics
      .filter(
        ({
          entity: {
            fieldTopicsIcon: { entity: iconEntity },
            fieldTopicsLink: { entity: linkEntity },
          },
        }) => iconEntity && linkEntity
      )
      .map(
        ({
          entity: {
            entityId,
            fieldTopicsIcon: { entity: iconImage },
            fieldTopicsLink: {
              entity: {
                entityLabel,
                entityUrl: { path, breadcrumb },
              },
            },
          },
        }) => (
          <Col lg={6} key={`popular-${entityId}`} className="d-flex">
            <LinkCard
              className="popular-topic-card flex-fill"
              heading={entityLabel}
              headingTag="h3"
              icon={iconImage}
              label={breadcrumb[1] ? breadcrumb[1].text : ' '}
              url={path}
              files={files}
            />
          </Col>
        )
      )}
  </Row>
);

export default PopularTopics;
