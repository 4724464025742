import React from 'react';
import { Button, Col, Dropdown, DropdownButton } from 'react-bootstrap';

const SelectionCol = ({ heading, current, id, items, onClick, ...props }) => (
  <Col {...props}>
    <h2 className="lead">{heading}</h2>

    <DropdownButton
      title={
        <span className={current ? 'font-weight-bold' : 'font-italic font-weight-normal'}>
          {current || 'Select an action'}
        </span>
      }
      className="tabc-dropdown"
      id={id}
      size="lg"
      variant="dark-blue"
    >
      {items.map(({ option }) => (
        <Dropdown.Item
          as={Button}
          className={`madlib-item ${option === current ? 'selected' : ''}`}
          eventKey={option}
          key={option}
          onClick={() => onClick(option)}
          size="lg"
        >
          <span>{option}</span>
        </Dropdown.Item>
      ))}
    </DropdownButton>
  </Col>
);

export default SelectionCol;
