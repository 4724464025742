import React from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { Icon, Link } from '../../../common';

const ResultsLinksCol = ({ links, listGroupClassName, ...props }) => (
  <Col {...props}>
    <ListGroup variant="flush" className={listGroupClassName}>
      {links.map(({ entity: { entityId, title, entityUrl: { path } } }, i) => (
        <ListGroup.Item
          as={Link}
          action
          key={`${i}-${entityId}`}
          className="lead text-left w-100 d-flex justify-content-between text-decoration-none madlib-result"
          href={path}
        >
          <span>{title}</span>
          <Icon name="arrow-right" />
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Col>
);

export default ResultsLinksCol;
