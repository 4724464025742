import React from 'react';
import { Card, Spinner } from 'react-bootstrap';

const Loader = ({ error }) => (
  <Card.Body className={!error && 'd-flex align-items-center justify-content-center'}>
    {!error && <Spinner animation="border" />}
    {error && <Card.Text>{error}</Card.Text>}
  </Card.Body>
);

export default Loader;
