import React from 'react';
import { Helmet } from 'react-helmet';
import { schema } from '../../lib/helpers/seo';
import Logo from "../../assets/logo/tabc-badge-lg.png";

export default ({ siteUrl }) => {
  const govtSchema = {
    areaServed: {
      "@type": "AdministrativeArea",
      "name": "Texas"
    },
    logo: siteUrl + Logo,
    name: "Texas Alcoholic Beverage Commission",
    url: siteUrl
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {schema("GovernmentOrganization", govtSchema)}
      </script>
    </Helmet>
  )
}

