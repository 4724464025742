const headers = { 'Content-Type': 'application/json' };
const key = `access_token=${process.env.FACEBOOK_API}`;
// the only way I could find to get the page ID for a facebook page is https://findmyfbid.com/
// the page access token in process.env.FACEBOOK_API needs to have admin permissions for this page
const pageId = '127158037386521';

export const fetchPost = async () => {
  if (!process.env.FACEBOOK_API) return;

  const feed = `https://graph.facebook.com/${pageId}/feed?${key}&limit=1&fields=id,created_time,message,permalink_url`;

  return await fetch(feed, { method: 'GET', headers })
    .then((response) => response.json())
    .then((data) => data.data[0])
    .catch((error) => {
      console.error('Facebook request failed', error);
      return { error: 'There was an error loading the post.' };
    });
};
