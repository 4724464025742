import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
// import Moment from 'react-moment';
import { Icon } from '../../../common';
import { fetchPost } from '../../../services/facebook';
import Loader from './Loader';
import { formatDateMMMDYYYY } from '../../../lib/helpers/date';

const Facebook = ({ className, url }) => {
  const pageName = url
    .replace(/\/+$/, '')
    .split('/')
    .pop();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getPost = async () => {
      const res = await fetchPost();
      if (res?.error) setError(res.error);
      else if (res) setPost(res);
    };
    getPost();
  }, []);

  return (
    <Card className={`social-card social-card-facebook ${className}`}>
      {!post && <Loader error={error} />}

      {post && <PostDetails post={post} />}

      <Card.Footer>
        <a href={url} target="_blank" rel="noopener">
          <Icon name="facebook" />
          <span>@{pageName}</span>
        </a>
      </Card.Footer>
    </Card>
  );
};

export default Facebook;

const PostDetails = ({ post: { created_time, message, permalink_url } }) => (
  <Card.Body className="d-flex flex-column">
    <Card.Text className="lead-2 font-weight-normal mb-7 flex-fill">
      <a href={permalink_url} target="_blank" rel="noopener">
        {message}
      </a>
    </Card.Text>

    <Card.Text className="meta">
      {/* <Moment date={created_time} format="MMMM D, YYYY [at] h:mm a" /> */}
      {formatDateMMMDYYYY(created_time)}
    </Card.Text>
  </Card.Body>
);
