import React from 'react';
import { Helmet } from 'react-helmet';
import { schema } from '../../lib/helpers/seo';
import useSearchPath from "../../hooks/useSearchPath";

export default ({ siteUrl }) => {
  const searchPath = useSearchPath();

  const searchboxSchema = {
    potentialAction: {
      "@type": "SearchAction",
      "target": `${siteUrl}${searchPath}/?query={search_term_string}`,
      "query-input": "required name=search_term_string" 
    },
    url: siteUrl
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {schema("WebSite", searchboxSchema)}
      </script>
    </Helmet>
  )
}
