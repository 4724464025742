import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Moment from 'react-moment';
import { Icon } from '../../../common';
import { fetchVideo } from '../../../services/youtube';
import Loader from './Loader';
import { formatDateMMMDYYYY } from '../../../lib/helpers/date';

const YouTube = ({ className, playlistId, url }) => {
  const [video, setVideo] = useState(null);
  const [error, setError] = useState(null);
  const channel = url
    .replace(/\/+$/, '')
    .split('/')
    .pop();

  useEffect(() => {
    const getVideo = async () => {
      const res = await fetchVideo({ playlistId });
      if (res?.error) setError(res.error);
      else if (res) setVideo(res);
    };
    getVideo();
  }, []);

  return (
    <Card className={`social-card social-card-youtube ${className}`}>
      {!video && <Loader error={error} />}

      {video && <VideoDetails playlistId={playlistId} video={video} />}

      <Card.Footer>
        <a href={url} target="_blank" rel="noopener">
          <Icon name="youtube" />
          <span>{channel}</span>
        </a>
      </Card.Footer>
    </Card>
  );
};

export default YouTube;

const VideoDetails = ({
  playlistId,
  video: {
    id,
    snippet: {
      description,
      publishedAt,
      thumbnails: {
        maxres: { url: thumb },
      },
      title,
    },
    statistics: { viewCount },
  },
}) => {
  const videoLink = `https://www.youtube.com/watch?list=${playlistId}&v=${id}`;

  return (
    <>
      <a href={videoLink} className="card-img-top d-block position-relative" target="_blank" rel="noopener">
        <Card.Img alt={title} src={thumb} variant="top" />
        <Icon name="play" className="position-absolute" />
      </a>

      <Card.Body className="d-flex flex-column">
        <Card.Title as="h3" className="h4">
          <a href={videoLink} target="_blank" rel="noopener">
            {title}
          </a>
        </Card.Title>

        <Card.Text className="lead font-weight-normal mb-4 flex-fill">{description}</Card.Text>

        <Card.Text className="meta">
          {/* {viewCount} views • <Moment date={publishedAt} format="MMMM D, YYYY" /> */}
          {viewCount} views • {formatDateMMMDYYYY(publishedAt)}
        </Card.Text>
      </Card.Body>
    </>
  );
};
