const headers = { 'Content-Type': 'application/json' };
const key = `key=${process.env.GOOGLE_MAPS_API}`;

export const fetchVideo = async ({ playlistId }) => {
  if (!process.env.GOOGLE_MAPS_API) return;

  const playlist = `https://www.googleapis.com/youtube/v3/playlistItems?${key}&playlistId=${playlistId}&part=snippet`;

  return await fetch(playlist, { method: 'GET', headers })
    .then((response) => response.json())
    .then((data) => {
      const videoId = data.items[0].snippet.resourceId.videoId;
      const videoUrl = `https://www.googleapis.com/youtube/v3/videos?${key}&id=${videoId}&part=snippet,statistics`;

      return fetch(videoUrl, { method: 'GET', headers });
    })
    .then((response) => response.json())
    .then((data) => data.items[0])
    .catch((error) => {
      console.error('Video request failed', error);
      return { error: 'There was an error loading the video.' };
    });
};
