import React from 'react';
import { graphql } from 'gatsby';
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import Helmet from 'react-helmet';

import { Heading, Image } from '../common';
import MadLibs from '../components/Home/MadLibs/MadLibs';
import PopularTopics from '../components/Home/PopularTopics';
import Posts from '../components/Home/Posts';
import OfficeZipCard from '../components/OfficeHub/OfficeZipCard';
import Facebook from '../components/Home/SocialFeeds/Facebook';
import Twitter from '../components/Home/SocialFeeds/Twitter';
import YouTube from '../components/Home/SocialFeeds/YouTube';
import GovernmentSchema from '../components/Home/GovernmentSchema';
import SearchSchema from '../components/Home/SearchSchema';
import TexasLogo from '../assets/logo/tabc-badge-favicon.png';

export const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
        social {
          facebook
          twitter
          youtube
        }
      }
    }
    drupal {
      homepage: nodeQuery(filter: { conditions: [{ field: "type", value: "home_page", operator: EQUAL }] }) {
        entities {
          ... on Drupal_NodeHomePage {
            nid
            fieldPageTitle
            fieldFeaturedMedia {
              entity {
                ...childImageField
              }
            }
            fieldMadLibs {
              entity {
                ...madLibs
              }
            }
            fieldPopularTopics {
              entity {
                ...popularTopics
              }
            }
            fieldPinnedPost {
              entity {
                entityId
              }
            }
            fieldNewsLink {
              entity {
                entityUrl {
                  path
                }
              }
            }
            fieldFacebook
            fieldTwitter
            fieldYoutube
          }
        }
      }
    }
    allSitePage(filter: { context: { postPage: { eq: true } } }) {
      nodes {
        path
        context {
          nid
        }
        children {
          ...childFiles
        }
      }
    }
    allFile {
      nodes {
        ...childFiles
      }
    }
  }
`;

const IndexPage = ({
  data: {
    drupal,
    site: {
      siteMetadata: {
        siteUrl,
        social: { facebook, twitter, youtube },
      },
    },
    allSitePage: { nodes: posts },
    allFile: { nodes: files },
  },
}) => {
  const drupalData = drupal.homepage.entities[0];
  const fieldPageTitle = drupalData.fieldPageTitle;
  const heroImg = drupalData.fieldFeaturedMedia.entity;
  const fieldMadLibs = drupalData.fieldMadLibs;
  const fieldPopularTopics = drupalData.fieldPopularTopics;
  const fieldPinnedPost = drupalData.fieldPinnedPost;
  const newsLink = drupalData.fieldNewsLink.entity.entityUrl.path;
  const fieldFacebook = drupalData.fieldFacebook;
  const fieldTwitter = drupalData.fieldTwitter;
  const fieldYoutube = drupalData.fieldYoutube;

  const pinnedPost = fieldPinnedPost?.entity?.entityId;
  const postFiles = pinnedPost ? posts.find(({ context: { nid } }) => nid === pinnedPost)?.children : [];

  return (
    <>
      <Helmet>
        <link rel="canonical" href={siteUrl} />
        <meta property="og:image" content={TexasLogo} />
      </Helmet>
      <GovernmentSchema siteUrl={siteUrl} />
      <SearchSchema siteUrl={siteUrl} />

      <div className="home-wrapper">
        <section className="pb-4 pb-xl-9">
          <Jumbotron fluid className="py-0">
            <Container>
              <Row>
                <Col lg={6} xl={7} className="pt-5 pb-15 pb-lg-5">
                  <Heading as="h1" className="mt-2">
                    {fieldPageTitle}
                  </Heading>
                </Col>
                <Col lg={6} xl={5} className="d-none d-lg-block pb-3">
                  {heroImg && (
                    <div className="mr-n4">
                      <Image entity={heroImg} files={files} />
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </Jumbotron>

          <Container className="mt-n10">
            <MadLibs madLibs={fieldMadLibs} breakpoint="xl" className="mb-8 mb-xl-13" />

            <Row>
              <Col xl={8} className="mb-8 mb-xl-0">
                <Heading as="h2" styledAs="h5" color="gray" className="mb-4">
                  Popular Topics
                </Heading>
                <PopularTopics popularTopics={fieldPopularTopics} files={files} />
              </Col>

              <Col xl={4} className="d-flex flex-column">
                <Heading as="h2" styledAs="h5" color="gray" className="mb-4">
                  Your TABC Office
                </Heading>
                <OfficeZipCard className="flex-fill" />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="py-8 py-xl-13">
          <Posts
            newsLink={newsLink}
            pinnedPost={pinnedPost}
            className={(fieldFacebook || fieldTwitter || fieldYoutube) && 'mb-9'}
            files={postFiles}
          />

          {(fieldFacebook || fieldTwitter || fieldYoutube) && (
            <Container>
              <Heading as="h2" styledAs="h5" color="gray" className="mb-4">
                Social Feed
              </Heading>
              <Row>
                {fieldYoutube && (
                  <Col lg={7}>
                    <YouTube playlistId={fieldYoutube} url={youtube} className="flex-fill" />
                  </Col>
                )}
                <Col lg={5} className="d-flex flex-column justify-content-stretch">
                  {fieldFacebook && <Facebook url={facebook} className="flex-fill" />}
                  {fieldTwitter && <Twitter url={twitter} className="flex-fill" />}
                </Col>
              </Row>
            </Container>
          )}
        </section>
      </div>
    </>
  );
};

export default IndexPage;
