import React, { useContext, useState, useRef, useEffect } from 'react';
import { Image, Card, Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import { GlobalContext } from '../Layout';
import useMountedZip from '../../hooks/useMountedZip';

import { Heading, Text, Link } from '../../common';
import LocationsImg from '../../assets/tabc-office-locations.png';
import Form from '../Form/Form';
import FormInput from '../Form/FormInput';
import FormInputClose from '../Form/FormInputClose';
import CardLoader from './CardLoader';

import { getGoogleMapsUrl, transformHtmlWithLink } from '../../lib/helpers';

const OfficeZipCard = ({ className = '', ...props }) => {
  const { zip, recommendedLocations, getNearestOffices, zipLoad, getUserLocation, zipError } = useContext(
    GlobalContext
  );
  const [showZipInput, updateShowZipInput] = useState(!zip);
  const [localLoad, updateLocalLoad] = useState(false);
  const [userLocationError, setUserLocationError] = useState(false);
  const zipInputRef = useRef();

  useMountedZip({ updateShowZipInput, zipLoad, zip });

  useEffect(() => {
    setUserLocationError(false);
  }, [showZipInput]);

  const onSubmit = async ({ zip }) => {
    try {
      const nearestOffices = await getNearestOffices({ zip });

      if (!nearestOffices || nearestOffices?.error)
        return {
          error: true,
          payload: {
            zip: zipError,
          },
        };
    } catch (e) {
      return {
        error: true,
        payload: {
          zip: zipError,
        },
      };
    }

    return { success: true };
  };

  const useLocation = async () => {
    updateLocalLoad(true);

    const userLocation = await getUserLocation();
    if (userLocation.error) {
      setUserLocationError(zipError);
    } else {
      setUserLocationError(false);
      updateShowZipInput(false);
    }

    updateLocalLoad(false);
  };

  const defaultState = (
    <div className="blurIn h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="mb-4 location-img-wrapper">
        <Image fluid src={LocationsImg} alt="Your TABC Office - TABC" className="mb-4" />
      </div>
      <Heading as="h3" styledAs="h5" className="mb-6 mx-xl-4 mx-xxl-6 text-center">
        Find the TABC office closest to you.
      </Heading>

      <Form
        isValid={({ value }) => value.length === 5}
        formProps={{ className: 'mb-6 d-flex flex-column justify-content-center align-items-center w-100' }}
        values={{ zip: '' }}
        submitText={'Find local office'}
        submitProps={{ className: 'mt-4 text-nowrap' }}
        onSuccess={updateShowZipInput.bind(null, false)}
        showTextWhileLoading={true}
        onSubmit={onSubmit}
      >
        {({ onChange, values, errors, resetField }) => (
          <div className="zipcode-wrapper">
            <FormInput
              type="zip"
              name="zip"
              error={errors['zip']}
              fakeError={userLocationError}
              onChange={onChange}
              value={values.zip}
              label="ZIP Code"
              placeholder="Enter ZIP Code"
              controlId="office-zip"
              ref={zipInputRef}
            />
            {values.zip && <FormInputClose onClick={() => resetField({ name: 'zip', targetRef: zipInputRef })} />}
          </div>
        )}
      </Form>

      <Button onClick={useLocation} variant="link" className="font-weight-bold">
        <span className="d-flex">Use your location</span>
      </Button>
    </div>
  );

  const zipState = () => {
    const {
      office: { fieldOfficeAddress, title, fieldOfficeServices, fieldPhoneNumber, fieldOfficeHours },
    } = recommendedLocations.regional;

    return (
      <div className="blurIn w-100">
        <Heading as="h3" styledAs="h3" className="mb-2 mt-3">
          {zip}
        </Heading>
        <Button onClick={updateShowZipInput.bind(null, true)} className="mb-5" variant="link">
          Change ZIP
        </Button>
        <Text fontWeight="bold">{title}</Text>
        <Text className="mt-3">{fieldOfficeAddress.addressLine1}</Text>
        {fieldOfficeAddress.addressLine2 && <Text>{fieldOfficeAddress.addressLine2}</Text>}
        <Text>
          {fieldOfficeAddress.locality}, TX {fieldOfficeAddress.postalCode}
        </Text>
        <Link className="mt-3 mb-3" href={getGoogleMapsUrl(fieldOfficeAddress)}>
          Get directions
        </Link>
        <Text>Phone: {fieldPhoneNumber}</Text>
        <Text as="div" className="contact-us__parsed mb-5">
          Open: {ReactHtmlParser(fieldOfficeHours.processed, { transform: transformHtmlWithLink })}
        </Text>
        <hr />
        <Text fontWeight="bold" className="mt-5 mb-3">
          This office can help with:
        </Text>
        {fieldOfficeServices.map(({ entity: { name } }) => (
          <Text key={name}>{name}</Text>
        ))}
      </div>
    );
  };

  return (
    <Card className={`office-zip-card ${className}`} {...props}>
      {(!zipLoad || localLoad) && <CardLoader />}
      <Card.Body className={`office-zip-card__body ${zipLoad && !localLoad ? 'loaded' : ''}`}>
        {!!recommendedLocations?.regional?.office && !showZipInput ? zipState() : defaultState}
      </Card.Body>
    </Card>
  );
};

export default OfficeZipCard;
