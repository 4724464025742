import React from 'react';
import { Card } from 'react-bootstrap';
import { Link, Image } from '../../common';

const LinkCard = ({ className = '', heading, headingTag = 'h3', headingStyledAs = 'h3', icon, label, url, files }) => (
  <Card className={`${className} link-card`}>
    <Card.Body className="d-flex flex-column justify-content-end">
      {label && <Card.Text className="mb-4">{label}</Card.Text>}
      {icon && <div className="card-icon mb-3">{icon && <Image entity={icon} files={files} />}</div>}
      <Card.Title as={headingTag} className={`${headingStyledAs} mb-4 flex-fill`}>
        {heading}
      </Card.Title>
      <Card.Link as={Link} href={url} className="stretched-link lead-2 popular-topics">
        Learn more
      </Card.Link>
    </Card.Body>
  </Card>
);

export default LinkCard;
