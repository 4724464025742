import React from "react";
import Card from "react-bootstrap/Card";
import { Icon } from "../../../common";

const Twitter = ({ className, url }) => {
  const twitterName = url.replace(/\/+$/, '').split('/').pop();

  return (
    <Card className={`social-card social-card-twitter ${className}`}>
      <Card.Body>
        <a
          className="twitter-timeline"
          href={url}
          data-tweet-limit="1"
          data-chrome="noheader nofooters noborders noscrollbar transparent"
        >
          Tweets by {twitterName}
        </a>
      </Card.Body>

      <Card.Footer>
        <a href={url} target="_blank" rel="noopener">
          <Icon name="twitter" />
          <span>@{twitterName}</span>
        </a>
      </Card.Footer>
    </Card>
  );
};

export default Twitter;
