import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Button, Col, Collapse, Row } from 'react-bootstrap';

import useSearchPath from '../../../hooks/useSearchPath';

import { Icon, Link } from '../../../common';
import SelectionCol from './SelectionCol';
import ResultsLinksCol from './ResultsLinksCol';

export const query = graphql`
  fragment madLibs on Drupal_ParagraphMadLibs {
    fieldMadlibsUser
    fieldMadlibsAction {
      entity {
        ... on Drupal_ParagraphMadLibsActions {
          fieldMadlibsActionName
          fieldMadlibsSearchTerm
          fieldMadlibsResults {
            entity {
              entityId
              title
              entityUrl {
                path
              }
            }
          }
        }
      }
    }
  }
`;

const halfResults = (arr) => Math.ceil((arr.length + 1) / 2); // half length excluding first result, with extra in first col

const MadLibs = ({ madLibs, className = '', breakpoint = 'xl' }) => {
  const searchPath = useSearchPath();

  const resultsListGroupClassName = `px-${breakpoint}-2`;

  let selectionColProps = {
    className: `py-3 py-${breakpoint}-5`,
  };
  selectionColProps[breakpoint] = 6;

  let resultsColProps = {};
  resultsColProps[breakpoint] = 4;

  const actionList = (user) => {
    const userActions = madLibs.find(({ entity: { fieldMadlibsUser } }) => fieldMadlibsUser === user);
    if (!userActions) return [];

    return userActions.entity.fieldMadlibsAction.map(
      ({ entity: { fieldMadlibsActionName, fieldMadlibsResults, fieldMadlibsSearchTerm } }) => ({
        option: fieldMadlibsActionName,
        results: fieldMadlibsResults,
        search: fieldMadlibsSearchTerm,
      })
    );
  };

  const resultsList = (user, action) => {
    const list = actionList(user).find(({ option }) => option === action);
    return list ? list.results : [];
  };

  const searchTerm = (user, action) => actionList(user).find(({ option }) => option === action)?.search;

  const users = madLibs.map(({ entity: { fieldMadlibsUser } }) => ({ option: fieldMadlibsUser })),
    [user, setUser] = useState(users[0].option),
    [action, setAction] = useState(false),
    results = resultsList(user, action),
    search = searchTerm(user, action);

  const urlParams = new URLSearchParams();

  urlParams.set('query', search);

  useEffect(() => {
    setAction(false);
  }, [user]);

  return (
    <div className={`madlibs-wrapper ${className}`}>
      <div className="madlibs bg-primary text-white">
        <div className={`selection-box px-3 px-${breakpoint}-5` + (!!action ? ' open' : '')}>
          <Row>
            <SelectionCol
              current={user}
              heading="I'm here as a"
              id="madlibs-dropdown-user"
              items={users}
              onClick={(item) => setUser(item)}
              {...selectionColProps}
            />
            <SelectionCol
              current={action}
              heading="And I'd like to"
              id="madlibs-dropdown-action"
              items={actionList(user)}
              onClick={(item) => setAction(item)}
              {...selectionColProps}
            />
          </Row>
        </div>

        <Collapse in={!!action}>
          <div>
            <div className={`results-box p-3 p-${breakpoint}-5`}>
              <h2 className="lead">Recommended Results</h2>

              {results.length > 0 && (
                <Row>
                  <Col {...resultsColProps}>
                    <div className={`first-result mb-3 pr-${breakpoint}-2`}>
                      <Button
                        as={Link}
                        className="w-100 text-left d-flex justify-content-between text-decoration-none madlib-result"
                        href={results[0].entity.entityUrl.path}
                        size="lg"
                        variant="primary"
                      >
                        <span className="font-weight-bold">{results[0].entity.title}</span>
                        <Icon name="arrow-right" />
                      </Button>
                    </div>
                  </Col>

                  {results.length > 1 && (
                    <ResultsLinksCol
                      links={results.slice(1, halfResults(results))}
                      listGroupClassName={resultsListGroupClassName}
                      {...resultsColProps}
                    />
                  )}

                  {results.length > 2 && (
                    <ResultsLinksCol
                      links={results.slice(halfResults(results), results.length)}
                      listGroupClassName={resultsListGroupClassName}
                      {...resultsColProps}
                    />
                  )}
                </Row>
              )}

              {search && (
                <div className={`mt-7 text-center text-${breakpoint}-right`}>
                  <Link className="text-warning lead" href={`${searchPath}/?${urlParams.toString()}`}>
                    View more
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default MadLibs;
